import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useWallet } from 'use-wallet';
import CenteredContent from '../components/CenteredContent';
import heading from '../assets/images/headings/webheader.webp';
import cardBack from '../assets/images/card-back.png';
import exploreRealmsHr from '../assets/images/explore-realms-hr.svg';
import createRealmImage from '../assets/images/create-realm.png';

import infuseNftImage from '../assets/images/infuse-nft.png';
import treeoflife from '../assets/images/treeoflife.gif';
import treeoflifepng from '../assets/images/treeoflife.png';
import genesis from '../assets/images/retrotvmobil.webp';
//import star from '../assets/images/star.svg';
//import plus from '../assets/images/plus.svg';
//import flag from '../assets/images/flag.svg';

const Container = styled.div`
  display: flex;
`;

const PageHeading = styled.img`
  margin: 3em 0 4em;
  margin-top: 10vh;
  max-width: 333px;
`;

const CardBackImage = styled.img`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 2em;
  padding: 10px;
  width: 15vw;
  min-width: 200px;
  min-height: 305px;
  max-width: 332px;
  max-height: 508px;
  object-fit: cover;
 backdrop-filter: blur(7px);  ;
  
  border-radius: 30px;
  transition: all 0.2s;
  border-radius: 44px;

box-shadow: inset 18px 18px 36px rgba(122, 193, 196,0.5),
            inset -18px -18px 36px rgba(194, 255, 255,0.5),
            0 0 30px 5px #9EFAFF;
  
  &:hover {
   
    border-color: #9EFAFF;
    box-shadow: 0 0 20px 4px #9EFAFF;
  }
`;
const SocialLink = styled.a`
  
  
`;

const PathImage = styled.img<{ width?: string }>`
  width: ${({ width }) => (width ? width : '100%')};
`;

const PathLabel = styled.h3`
  margin-top: 2.5em;
  font-family: '3616 Grammastile', sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
`;

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;


const ExploreRealmsHr = styled.img`
  margin-top: 45px;
`;

const ExploreRealmsLink = styled(Link)`
  margin-top: 15px;
  font-size: 14px;
  color: #9EFAFF;
`;

export default () => {
  const wallet = useWallet();
  const isMobile = window.innerWidth <= 900;
  return (
    <CenteredContent>
      <PageHeading src={heading} alt="#Breakthefuture" />

      <Container>
        <StyledLink to="/genesis">
          <CardBackImage src={treeoflife} alt="Create Realm" />
         

          <PathLabel>
            Genesis
            <br />
            collection
          </PathLabel>
        </StyledLink>

       

      </Container>

      <ExploreRealmsHr src={exploreRealmsHr} alt="" />
      <SocialLink
      href='https://docs.google.com/forms/d/1Xa6QJuC6OKLWeR1M4CyogGhNQkuGf1Ka2AZdQXsONn4/viewform?edit_requested=true&pli=1'
      target="_blank"
      rel="noreferrer"
    >
       I have no clue what  NFT's are
      </SocialLink>
    </CenteredContent>
  );
};
