import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { useWallet } from 'use-wallet';
import SocialLinks from '../components/SocialLinks';
import CenteredContent from '../components/CenteredContent';
import heading from '../assets/images/headings/webheader.webp';
import cardBack from '../assets/images/card-back.png';
import exploreRealmsHr from '../assets/images/explore-realms-hr.svg';
import createRealmImage from '../assets/images/create-realm.png';
import buildyourown from '../assets/images/buildyourown.gif';
import newcollection from '../assets/images/newcollection.gif';
import infuseNftImage from '../assets/images/infuse-nft.png';
import claimTokensImage from '../assets/images/claim-tokens.png';
import genesis from '../assets/images/retrotvmobil.webp';
import genesisweb from '../assets/images/retrotrans.webp';
import alien from '../assets/images/alien1.webm';
import login from '../assets/images/login.png';
//import star from '../assets/images/star.svg';
//import plus from '../assets/images/plus.svg';
//import flag from '../assets/images/flag.svg';

const Container = styled.div`
  display: flex;
`;

const PageHeading = styled.img`
  margin: 3em 0 4em;
  max-width: 333px;
`;

const CardBackImage = styled.img<{ isMobile?: boolean; tinyHeight?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${({ isMobile }) => (isMobile ? '100vw' : '100vw')};
  height: ${({ isMobile }) => (isMobile ? '120vw' : '100vh')};
  top: ${({ isMobile }) => (isMobile ? '-13vh' : '0')};
  left: ${({ isMobile }) => (isMobile ? '-0vw' : '0')};
  z-index: 3;

  transition: all 0.2s;
  pointer-events: none;
  &:hover {
    border-color: #17ffe3;
    box-shadow: 0 0 20px 4px #9EFAFF;
  }
`;
const mover = keyframes`
0% { transform: translateY(0);
transform: scale(1);}
100% { transform: translateY(-5px);
  transform: scale(1.05); }

`;
const Login = styled.img<{ isMobile?: boolean }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10%;

  width: ${({ isMobile }) => (isMobile ? '20vw' : '15vw')};
  height: ${({ isMobile }) => (isMobile ? '5vh' : '10vh')};
  top: ${({ isMobile }) => (isMobile ? '12vh' : '-10vh')};
  left: ${({ isMobile }) => (isMobile ? '40vw' : '42.5%')};
  z-index: 2;

  transition: all 0.2s;
  animation: ${mover} 3s infinite alternate;
  filter: brightness(0.8);
  &:hover {
    border-color: #17ffe3;
    box-shadow: 0 0 20px 4px #9EFAFF;
  }
`;

const PathImage = styled.img<{ width?: string }>`
  width: ${({ width }) => (width ? width : '100%')};
`;

const PathLabel = styled.h3`
  position: relative;
  font-family: '3616 Grammastile', sans-serif;
  font-size: 0.6rem;
  font-weight: 400;
  line-height: 1.2rem;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  padding-left: 10vw;
  padding-right: 10vw;
  top: -10vh;
`;

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

const ExploreRealmsHr = styled.img`
  margin-top: 45px;
`;

const ExploreRealmsLink = styled(Link)`
  margin-top: 15px;
  font-size: 14px;
  color: #9EFAFF;
`;

export default () => {
  const wallet = useWallet();
  const isMobile = window.innerWidth <= 900;
  const tinyHeight = window.innerHeight <= 710;
  const section = location.pathname;

  return (
    <CenteredContent>
      <SocialLinks />

      <Container>
        <StyledLink to="/enter-rave">
          <div>
            <CardBackImage
              isMobile={isMobile}
              tinyHeight={tinyHeight}
              src={isMobile ? genesis : genesisweb}
              alt="Create Realm"
            />
            <video
              autoPlay
              loop
              muted
              poster="/scenes/alien1.webp"
              style={{
                position: 'absolute',
                objectFit: 'fill',

                width: isMobile ? '100vw' : '100vw',
                height: isMobile ? '30vh' : '58vh',
                top: isMobile ? (tinyHeight ? '0vh' : '5vh') : '6vh',
                left: isMobile ? '8%' : '27%',
                maxWidth: isMobile ? '85%' : '47%',
              }}
            >
              <source src={alien} type="video/webm" />
            </video>
          </div>

          <StyledLink to="/enter-rave">
            <Login
              src={login}
              alt="Create Realm"
              isMobile={isMobile}
              style={{
                top: isMobile ? (tinyHeight ? '3vh' : '9vh') : '18vh',
              }}
            ></Login>
          </StyledLink>
          <PathLabel
            style={{
              zIndex: '4',
              top: isMobile ? (tinyHeight ? '-16vh' : '-5vh') : '-12vh',
            }}
          >
            This event wishes to unveil the{' '}
            <a
              style={{
                fontWeight: '600',
                color: '#9EFAFF',
                filter: 'brightness(1.2)',
              }}
            >
              portal
            </a>{' '}
            between technology and presence, an orchestration to
            <a
              style={{
                fontWeight: '600',
                color: '#9EFAFF',
                filter: 'brightness(1.2)',
              }}
            >
              {' '}
              rebalance the galaxy{' '}
            </a>{' '}
            and a glimpse into the streams we wish to be guided by as a
            collective of{' '}
            <a
              style={{
                fontWeight: '600',
                color: '#9EFAFF',
                filter: 'brightness(1.2)',
              }}
            >
              oneness
            </a>
            .
            <div
              style={{
                marginTop: '3vh',
                filter: 'brightness(1.5)',
                color: 'rgba(136,209,24)',
                textDecoration: 'underline',
              }}
            >
              Log In
            </div>
          </PathLabel>
        </StyledLink>
      </Container>
    </CenteredContent>
  );
};
