import styled from 'styled-components';
import discordIcon from '../assets/images/icons/discord.svg';
import twitterIcon from '../assets/images/icons/twitter.svg';
import githubIcon from '../assets/images/icons/facebook.svg';
import { useLocation } from 'react-router-dom';
const Container = styled.div`
  position: fixed;
  bottom: 2vh;
  left: 35vw;
  display: flex;
  flex-direction: row;
  
  
`;

const SocialLink = styled.a`
  margin-right: 5vw;
  display: inline-block;
`;

const isMobile = window.innerWidth <= 900;
export default () => (
  <Container>
  
   { isMobile &&( <div>
    <SocialLink
      href="https://discord.gg/exitheweb"
      target="_blank"
      rel="noreferrer"
    >
      <img src={discordIcon} alt="Discord" />
    </SocialLink>

    <SocialLink
      href="https://twitter.com/mopsgathering"
      target="_blank"
      rel="noreferrer"
    >
      <img src={twitterIcon} alt="Twitter" />
    </SocialLink>

    <SocialLink
      href="https://www.facebook.com/events/5465604880188938"
      target="_blank"
      rel="noreferrer"
    >
      
          <svg xmlns="http://www.w3.org/2000/svg"fill="#9EFAFF" width="22" height="22" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3 8h-1.35c-.538 0-.65.221-.65.778v1.222h2l-.209 2h-1.791v7h-3v-7h-2v-2h2v-2.308c0-1.769.931-2.692 3.029-2.692h1.971v3z"/></svg>
           
    </SocialLink>
    </div>)}
    </Container>
);
