import styled from 'styled-components';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import useCreateRealmWizard, {
  RealmWizardValues,
} from '../hooks/useCreateRealmWizard';
import MintGenesisContainer from '../components/MintGenesisContainer';

import InputGroup from '../components/InputGroup';
import SwitchGroup from '../components/SwitchGroup';
import Switch from '../components/Switch';
import ButtonGroup from '../components/ButtonGroup';
import BackButton from '../components/BackButton';
import SubmitButton from '../components/SubmitButton';
import { MINT_STEPS } from '../constants/mintSteps';
import TextInput from '../components/TextInput';
import heading from '../assets/images/headings/set-up-infusion.svg';

import allowAnyInfuserImage from '../assets/images/allow-any-infuser.png';
import allowAnyInfuserSelectedImage from '../assets/images/allow-any-infuser-selected.png';
import allowSpecificInfusersImage from '../assets/images/allow-specific-addresses.png';
import allowSpecificInfusersSelectedImage from '../assets/images/allow-specific-addresses-selected.png';

const Container = styled.div``;

const InfusionOptionImage = styled.div`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-image 0.2s;
`;

const AllowAnyInfuser = styled(InfusionOptionImage)<{ isSelected: boolean }>`
  background-size: 70%;

  background-image: ${({ isSelected }) =>
    isSelected
      ? `url(${allowAnyInfuserSelectedImage})`
      : `url(${allowAnyInfuserImage})`};

  &:hover {
    background-image: url(${allowAnyInfuserSelectedImage});
  }
`;

const AllowSpecificInfusers = styled(InfusionOptionImage)<{
  isSelected: boolean;
}>`
  background-size: 41%;

  background-image: ${({ isSelected }) =>
    isSelected
      ? `url(${allowSpecificInfusersSelectedImage})`
      : `url(${allowSpecificInfusersImage})`};

  &:hover {
    background-image: url(${allowSpecificInfusersSelectedImage});
  }
`;
const Heading = styled.div`
  margin-bottom: 3em;
  height: 20px;
  font-size: 2.5rem;
`;
export default () => {
  const { realm, updateRealm } = useCreateRealmWizard();
  const methods = useForm<RealmWizardValues>({ defaultValues: realm });
  const allowPublicInfusion = methods.watch('allowPublicInfusion');
  const history = useHistory();

  const onSubmit = methods.handleSubmit(data => {
    updateRealm(data);
    history.push('/mint');
  });
  const isMobile = window.innerWidth <= 900;
  return (
    <Container>
      <FormProvider {...methods}>
        <MintGenesisContainer
          name="Guest Info"
          steps={MINT_STEPS}
          activeStep={3}
        >
          <Heading>Guest Info</Heading>
          <form onSubmit={onSubmit}>
            <InputGroup label="Guest" description="Your Name and Email">
              <TextInput name="username" description="Your Name" required />
              <TextInput name="email" description="Your Email" required />
            </InputGroup>
            <InputGroup label="Socials" description="If you feel leave us your socials">
              <TextInput name="twitter" description="Twitter" />
              <TextInput name="discord" description="Discord" />
              <TextInput name="linkedin" description="Linkedin" />
            </InputGroup>

            <SwitchGroup
              name="allowMultiInfusion"
              label="Do you want to participate in our future Gatherings?"
              
            >
              <Switch name="allowMultiInfusion" id="yes" label="Yes" />
              <Switch name="allowMultiInfusion" id="no" label="No" />
            </SwitchGroup>

            <ButtonGroup>
              <BackButton path="/lineup" />
              <SubmitButton>Next</SubmitButton>
            </ButtonGroup>
          </form>
        </MintGenesisContainer>
      </FormProvider>
    </Container>
  );
};
