const CHAIN_CONFIG = {
  polygon: {
    NAME: 'matic',
    SYMBOL: 'Matic',
    ID: 137,
    SCAN_LINK: 'https://polygonscan.com',
    RPC_URL: 'https://polygon-rpc.com',
  },
  optimism: {
    NAME: 'Optimism',
    SYMBOL: 'ETH',
    ID: 10,
    SCAN_LINK: 'https://optimistic.etherscan.io',
    RPC_URL: 'https://mainnet.optimism.io',
  },
  binance: {
    NAME: 'Binance Smart Chain',
    SYMBOL: 'BNB',
    ID: 56,
    SCAN_LINK: 'https://bscscan.com',
    RPC_URL: 'https://bsc-dataseed1.binance.org',
  },
  mainnet: {
    NAME: 'mainnet',
    SYMBOL: 'ETH',
    ID: 1,
    SCAN_LINK: 'https://etherscan.io',
    RPC_URL: 'https://mainnet.infura.io/v3/',
  },
  arbitrum: {
    NAME: 'Arbitrum',
    SYMBOL: 'ETH',
    ID: 42161,
    SCAN_LINK: 'https://arbiscan.io',
    RPC_URL: 'https://arb1.arbitrum.io/rpc',
  },
  goerli: {
    NAME: 'goerli',
    SYMBOL: 'ETH',
    ID: 5,
    SCAN_LINK: 'https://goerli.etherscan.io',
    RPC_URL: 'https://goerli.infura.io/v3/',
  },
}

export const CONFIG = {
  DEV: true,
  BACKEND_URL:{

  },  
  MAINNET: {
    address: '0x60bf32a8216753f47632e0f62ED3Ce683b474a93',
    DEV_CONFIG: CHAIN_CONFIG['mainnet'],
    MAIN_CONFIG: CHAIN_CONFIG['mainnet'],
  },
  GOERLI: {
    address: '0x4B91E7715dc3f4bD2c64388145f46b9a16bE7D20',
    DEV_CONFIG: CHAIN_CONFIG['goerli'],
    MAIN_CONFIG: CHAIN_CONFIG['goerli'],
  },
  POLYGON: {
    address: '0x60bf32a8216753f47632e0f62ED3Ce683b474a93',
    DEV_CONFIG: CHAIN_CONFIG['polygon'],
    MAIN_CONFIG: CHAIN_CONFIG['polygon'],
  },
  ARBITRUM: {
    address: '0x60bf32a8216753f47632e0f62ED3Ce683b474a93',
    DEV_CONFIG: CHAIN_CONFIG['arbitrum'],
    MAIN_CONFIG: CHAIN_CONFIG['arbitrum'],
  },
  OPTIMISM: {
    address: '0x60bf32a8216753f47632e0f62ED3Ce683b474a93',
    DEV_CONFIG: CHAIN_CONFIG['optimism'],
    MAIN_CONFIG: CHAIN_CONFIG['optimism'],
  },
  BINANCE: {
    address: '0x60bf32a8216753f47632e0f62ED3Ce683b474a93',
    DEV_CONFIG: CHAIN_CONFIG['binance'],
    MAIN_CONFIG: CHAIN_CONFIG['binance'],
  },
  
}
