import styled from 'styled-components';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import useCreateRealmWizard, {
  RealmWizardValues,
} from '../hooks/useCreateRealmWizard';
import MintGenesisContainer from '../components/MintGenesisContainer';
import FormHeading from '../components/FormHeading';
import TextInput from '../components/TextInput';
import MultiAddressInput from '../components/MultiAddressInput';
import ButtonGroup from '../components/ButtonGroup';
import BackButton from '../components/BackButton';
import SubmitButton from '../components/SubmitButton';
import { MINT_STEPS } from '../constants/mintSteps';
import unicorn from '../assets/images/unicorn.svg';
import treeoflife from '../assets/images/treeoflife.gif';
const Container = styled.div``;
const Description = styled.div`
  display: flex;
  flex-direction: row;
`;
const Text = styled.div`
  margin-bottom: 2rem;
`;
const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
`;
const CardBackImage = styled.img`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 2em;
  padding: 10px;
  height:33vh;
  width: 15vw;
  top:-7vh;
  
  object-fit: cover;
 backdrop-filter: blur(7px);  ;
  
  border-radius: 30px;
  transition: all 0.2s;
  border-radius: 44px;


  
  &:hover {
   
    border-color: #17ffe3;
   
  }
`;
const Heading = styled.div`
  margin-bottom: 3em;
  height: 20px;
  font-weight: 600;
  font-size: 2.5rem;
`;

export default () => {
  const { realm, updateRealm } = useCreateRealmWizard();
  const methods = useForm<RealmWizardValues>({ defaultValues: realm });
  const history = useHistory();

  const onSubmit = methods.handleSubmit(data => {
    updateRealm(data);
    history.push('/lineup');
  });
  const isMobile = window.innerWidth <= 900;

  return (
    <Container>
     
        <MintGenesisContainer name="Genesis" steps={MINT_STEPS} activeStep={1}>
        {!isMobile &&(<Heading>Exit the Web Genesis NFT</Heading>)}
        {isMobile &&(<Heading>Genesis NFT</Heading>)}
          <form onSubmit={onSubmit}>
            <Description>
              <div>
                <Text>
                  <Title>Welcome to Our Gathering</Title>
                  This event wishes to unveil the{' '}
                  <a
                    style={{
                      fontWeight: '600',
                      color: '#9EFAFF',
                    }}
                  >
                    portal
                  </a>{' '}
                  between technology and presence, an orchestration to
                  <a
                    style={{
                      fontWeight: '600',

                      color: '#9EFAFF',
                      textShadow: '0 0 7px #fff,0 0 10px #fff,0 0 21px #fff,',
                    }}
                  >
                    {' '}
                    rebalance the galaxy{' '}
                  </a>{' '}
                  and a glimpse into the streams we wish to be guided by as a
                  collective of{' '}
                  <a
                    style={{
                      fontWeight: '600',
                      color: '#9EFAFF',
                    }}
                  >
                    oneness
                  </a>
                  .
                </Text>
                
              </div>
            
             {!isMobile &&( <CardBackImage src={unicorn} alt="Create Realm" style={{transform:"scale(-1,1)"}}/>)}
            </Description>
              <Text style={{marginLeft:"" ,display:"flex", flexDirection:"row"}}>
              {!isMobile &&( <CardBackImage src={treeoflife} alt="Create Realm" style={{left: "-5vw",top:"0vh"}}/>)}
             <div style={{position:"relative",left:isMobile ? "":"-5vw"}}>
                  <Title>The Mission</Title>
                  Joining forces in a light beam bringing us a future that
                  spirals upwards in co-creation rooted in the essence of being,
                  this is the{' '}
                  <a
                    style={{
                      fontWeight: '600',
                      color: '#9EFAFF',
                    }}
                  >
                    strike back{' '}
                  </a>
                  against those who wish to separate us. We welcome you with
                  perfect love and perfect trust that we all shall dance as one,
                  coming together to break all doubts of the future and
                  finishing the mission left to us...
                  </div>
                </Text>
            <Text>
             It is in your hands the fate of our collective experience, you are part of the creator
             and you can take part in co-creating a world free from mental and physical enslavement.
             Together we can open the portal needed to free mankind from the shakels of limmiting beleifs.
             
            </Text>

            <ButtonGroup>
              <BackButton path="/enter-rave"/>
              <SubmitButton>Next</SubmitButton>
            </ButtonGroup>
          </form>
        </MintGenesisContainer>
      
    </Container>
  );
};
