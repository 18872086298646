import styled from 'styled-components';
import FormSteps, { FormStep } from './FormSteps';
import borderPatternVertical from '../assets/images/create-realm-border-pattern-vertical.png';
import borderPatternHorizontal from '../assets/images/create-realm-border-pattern-horizontal.png';
import stepOneIcon from '../assets/images/icons/step-1.svg';
import stepTwoIcon from '../assets/images/icons/step-2.svg';
import stepThreeIcon from '../assets/images/icons/step-3.svg';
import stepFourIcon from '../assets/images/icons/step-4.svg';
import star from '../assets/images/unicorn.svg';

interface Props {
  name: string;
  steps: FormStep[];
  activeStep: number;
  children: React.ReactNode;
}

const BackgroundContainerVertical = styled.div`
  position: relative;
  padding-left: 3em;
  background: url(${borderPatternVertical}) left top repeat-y;
  background-size: 24px 388px;
`;

const BackgroundContainerHorizontal = styled.div`
  padding-bottom: 3em;
  margin-left: -3em;
  background: url(${borderPatternHorizontal}) left bottom repeat-x;
  background-size: 388px 24px;
`;

const BorderContainer = styled.div`
  position: relative;
  padding: 1px;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.8);
  background: radial-gradient(rgba(87, 78, 113, 0.9),rgba(0, 0, 0, 0.9));
  
`;

const Container = styled.div`
  background:rgba(87, 78, 113, 0.9));
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  width: 85vw;
  max-width: 784px;
`;



const ThreeLinePattern = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2px;
`;

const LinePattern = styled.div`
  margin-top: 6px;
  width: 90%;
  max-width: 704px;
  border-top: 1px solid #9EFAFF;
`;

const StepIcon = styled.img`
  position: absolute;
  top: 1em;
  right: 1em;
`;

const Star = styled.img`
  position: absolute;
  transform: scale(-1,1);
  width:40px;
  bottom: 1.5em;
  left: 1em;
`;
const isMobile = window.innerWidth <= 900;
export default ({ name, steps, activeStep, children }: Props) => (
  <>
    <FormSteps steps={steps} activeStep={activeStep} />

    
    <BorderContainer>
    
      <Container>
     
        <ThreeLinePattern>
          <LinePattern />
          <LinePattern />
          <LinePattern />
        </ThreeLinePattern>
        <Star src={star} alt="" />

        <Content style={{ padding: isMobile ? "3em 1.3em 1.5em" :"3em 4.0em 4.5em"}} >
        

          {children}
        </Content>
      </Container>
    </BorderContainer>
  </>
);
