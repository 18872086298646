import styled from 'styled-components';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import useCreateRealmWizard, {
  RealmWizardValues,
} from '../hooks/useCreateRealmWizard';
import MintGenesisContainer from '../components/MintGenesisContainer';
import FormHeading from '../components/FormHeading';
import RadioGroup from '../components/RadioGroup';
import RadioButtonCard from '../components/RadioButtonCard';

import TextInput from '../components/TextInput';
import ButtonGroup from '../components/ButtonGroup';
import BackButton from '../components/BackButton';
import SubmitButton from '../components/SubmitButton';
import { MINT_STEPS } from '../constants/mintSteps';
import heading from '../assets/images/headings/select-collections.svg';
import allowAnyCollectionImage from '../assets/images/allow-any-collection.png';
import allowAnyCollectionSelectedImage from '../assets/images/allow-any-collection-selected.png';
import allowSpecificCollectionsImage from '../assets/images/allow-specific-collections.png';
import allowSpecificCollectionsSelectedImage from '../assets/images/allow-specific-collections-selected.png';

const Container = styled.div``;

const CollectionOptionImage = styled.div`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-image 0.2s;
`;

const AllowAnyCollection = styled(CollectionOptionImage)<{
  isSelected: boolean;
}>`
  background-size: 45%;

  background-image: ${({ isSelected }) =>
    isSelected
      ? `url(${allowAnyCollectionSelectedImage})`
      : `url(${allowAnyCollectionImage})`};

  &:hover {
    background-image: url(${allowAnyCollectionSelectedImage});
  }
`;

const AllowSpecificCollections = styled(CollectionOptionImage)<{
  isSelected: boolean;
}>`
  background-size: 23%;

  background-image: ${({ isSelected }) =>
    isSelected
      ? `url(${allowSpecificCollectionsSelectedImage})`
      : `url(${allowSpecificCollectionsImage})`};

  &:hover {
    background-image: url(${allowSpecificCollectionsSelectedImage});
  }
`;

const Heading = styled.div`
  margin-bottom: 2em;
  height: 20px;
  font-size: 2.5rem;
`;
const Text = styled.div`
  margin-bottom: 2rem;
`;
const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
`;
export default () => {
  const { realm, updateRealm } = useCreateRealmWizard();
  const methods = useForm<RealmWizardValues>({ defaultValues: realm });
  const allowAllCollections = methods.watch('allowAllCollections');
  const history = useHistory();

  const onSubmit = methods.handleSubmit(data => {
    updateRealm(data);
    history.push('/guest-info');
  });

  return (
    <Container>
      <FormProvider {...methods}>
        <MintGenesisContainer name="Line Up" steps={MINT_STEPS} activeStep={2}>
          <form onSubmit={onSubmit}>
            <Heading>Line Up</Heading>
            <Text>
              <Title> 00:00  | Opening The Portal </Title>
            </Text>
            <Text>
              <Title>00:30 | Timo | Starting to Move </Title>
              Timo will make us slowly come into{' '}
              <a
                style={{
                  fontWeight: '600',
                  color: '#9EFAFF',
                }}
              >
                movement
              </a>{' '}
              dissolving our boundries and making us free ourselves from
              shyness. He will slowly pick up steam and leave us in the perfect
              state for the{' '}
              <a
                style={{
                  fontWeight: '600',

                  color: '#9EFAFF',
                  textShadow: '0 0 7px #fff,0 0 10px #fff,0 0 21px #fff,',
                }}
              >
                take off.
              </a>{' '}
              .
            </Text>
            <Text>
              <Title>02:00 | Psara | The heart of the Galaxy </Title>
              Psara will make us fly high and set the controls for the heart of
              the{' '}
              <a
                style={{
                  fontWeight: '600',
                  color: '#9EFAFF',
                }}
              >
                galaxy
              </a>{' '}
              ,taking us trough a really long soundscape journey filled with
              ecstasis and bringing us the
              <a
                style={{
                  fontWeight: '600',

                  color: '#9EFAFF',
                  textShadow: '0 0 7px #fff,0 0 10px #fff,0 0 21px #fff,',
                }}
              >
                {' '}
                revelation{' '}
              </a>{' '}
              needed to fulfill our destiny in this unique inter-galatic
              gathering. We shall be united as one once again and the brave
              cosmic warriors will receive the activation codes to restore
              balance to the universe.
            </Text>
            <Title>06:00 | Closing the Portal | The Journey Home</Title>

            <ButtonGroup>
              <BackButton path="genesis" />
              <SubmitButton>Next</SubmitButton>
            </ButtonGroup>
          </form>
        </MintGenesisContainer>
      </FormProvider>
    </Container>
  );
};
