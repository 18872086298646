import { useCallback } from 'react';
import { createGlobalState } from 'react-hooks-global-state';
import { utils } from 'ethers';
import useDahcorContract from './useDahcorContract';

interface GlobalState {
  
  webraveGenesis: DahcorGenesisValues;
}

const initialState: GlobalState = {
  
  webraveGenesis: {
    chain:'ethereum',
   address:'',
    name: '',
    email: '',
    twitter: '',
    discord: '',
    linkedin: '',
    daoUser: 'no',
    
   promocode:'',

  },
};

export interface DahcorGenesisValues {
  chain:string | null;
 address: string;
  name: string;
  email: string;
  twitter: string;
  discord: string;
  linkedin: string;
  
  daoUser: 'yes' | 'no';
  promocode:string;
}

const { useGlobalState } = createGlobalState(initialState);


export default () => {
  const [webrave, updateDahcor] = useGlobalState('webraveGenesis');
  const webraveGenesisContract = useDahcorContract();
let isPolygon = webrave.chain === "matic";

  const mintGenesis = useCallback(
    async (webrave: DahcorGenesisValues) => {
      if(webrave.promocode){
        console.log("is polygon yes?",isPolygon)
        console.log("chain is",webrave.chain)
        if(webrave.chain === "matic"){
          console.log("is polygon yes?",isPolygon)
          console.log(webrave.promocode.toUpperCase())
      return webraveGenesisContract?.mint_promo(webrave.promocode.toUpperCase(),{
        value: utils.parseUnits( '30', 18),
        gasLimit: 500000,
      });}
      else{
      return webraveGenesisContract?.mint_promo(webrave.promocode.toUpperCase(),{
        value: utils.parseUnits( "222",  14),
        gasLimit: 500000,
      });}
    }
    else{
      if(webrave.chain === "matic")
      return webraveGenesisContract?.mint(webrave.address, {
        value: utils.parseUnits( '60', 18),
        gasLimit: 500000,
      });
      else
      return webraveGenesisContract?.mint(webrave.address, {
        value: utils.parseUnits( '333', 14),
        gasLimit: 500000,
      });

    }

    },
    [webraveGenesisContract]
  );

  return {
    
    webrave,
    updateDahcor: (fields: DahcorGenesisValues) =>
      updateDahcor({ ...webrave, ...fields }),
    mintGenesis,
    resetDahcor: () => updateDahcor(initialState.webraveGenesis),
  };
};
