import { useWallet } from 'use-wallet';
import useContract from './useContract';
import webraveGenesisAbi from '../constants/abis/DahcorGenesis.json';
import { EXITWEB_GENESIS_CONTRACT_ADDRESSES } from '../constants/contracts';

export default () => {
  const { chainId = 1 } = useWallet();
  const contract = useContract(
    EXITWEB_GENESIS_CONTRACT_ADDRESSES[chainId],
    webraveGenesisAbi
  );

  return contract;
};
