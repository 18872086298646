export const MINT_STEPS = [
  {
    label: 'Event Info',
    path: 'genesis',
  },
  {
    label: 'Line Up',
    path: 'lineup',
  },
  {
    label: 'Guest Info',
    path: 'guest-info',
  },
  {
    label: 'Mint Ticket',
    path: 'mint',
  },
];
